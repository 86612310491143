<template src="./consultingRoom.html"></template>

<script>
import mixins from "../../../mixins/mixins.js";
////////////////////// Chat texto //////////////////////////////////////////////
// https://github.com/MatheusrdSantos/vue-quick-chat
import { Chat } from "vue-quick-chat";
import "vue-quick-chat/dist/vue-quick-chat.css";
export default {
  mixins: [mixins],
  components: {
    Chat,
  },
  data() {
    return {
      showIconCam: true,
      cardRtc: true,
      specialty: "",
      crmNumber: "",
      roomUrl: "",
      //    chat room    //
      accessCam: false,
      accessMic: false,
      isChrome: undefined,
      isWkWebView: false,
      popupCamChrome: false,
      supportsTouch: undefined,
      videoEnabled: true,
      audioEnabled: true,
      //                 //
      ////////////////////// Chat texto //////////////////////////////////////////////
      visible: true,
      participants: [
        {
          name: "Profissional da saúde",
          id: null,
          profilePicture: null,
        },
      ],
      myself: {
        name: "Paciente",
        id: null,
        profilePicture: null,
      },
      messages: [],
      chatTitle: "Sala de atendimento",
      placeholder: "Escreva e envie sua mensagem",
      colors: {
        header: {
          bg: "#FF9F43",
          text: "#fff",
        },
        message: {
          myself: {
            bg: "#fff",
            text: "#bdb8b8",
          },
          others: {
            bg: "#FF9F43",
            text: "#fff",
          },
          messagesDisplay: {
            bg: "#f7f3f3",
          },
        },
        submitIcon: "#E56B1F",
        submitImageIcon: "#FF9F43",
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px",
      },
      hideCloseButton: true,
      submitIconSize: 25,
      closeButtonIconSize: "20px",
      asyncMode: false,
      toLoad: [],
      scrollBottom: {
        messageSent: true,
        messageReceived: false,
      },
      displayHeader: true,
      profilePictureConfig: {
        others: false,
        myself: false,
        styles: {
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        },
      },
      timestampConfig: {
        format: "HH:mm",
        relative: false,
      },
      // there are other options, you can check them here
      // https://soapbox.github.io/linkifyjs/docs/options.html
      linkOptions: {
        myself: {
          className: "myLinkClass",
          events: {
            click: function () {
              alert("Link clicked!");
            },
            mouseover: function () {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
        others: {
          className: "othersLinkClass",
          events: {
            click: function () {
              alert("Link clicked!");
            },
            mouseover: function () {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
      },
    };
  },
  created: function () {
    const vm = this;
    vm.isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    vm.supportsTouch = "ontouchstart" in window || navigator.msMaxTouchPoints;
    vm.verificaChrome();
    vm.verificaPWA();
    vm.verficaWkWebView();
    //////// Chat texto ////////
    if (window.addEventListener) {
      window.addEventListener(
        "message",
        function (result) {
          const resultado = result;
          if (resultado.data[0] === "mensagemChatInPac") {
            vm.getMessageVidu(resultado.data[1]);
          }
        },
        false
      );
    } else {
      window.attachEvent(
        "onmessage",
        function (result) {
          const resultado = result;
          if (resultado.data[0] === "mensagemChatInPac") {
            vm.getMessageVidu(resultado.data[1]);
          }
        },
        false
      );
    }
    //////// Chat texto ////////
  },
  destroyed: function () {
    //let vm = this;
  },
  mounted: function () {
    var vm = this;
    vm.myself.name = vm.$session.get("name");
    vm.init();
  },
  beforeRouteLeave(to, from, next) {
    var vm = this;
    vm.exitRoom();
    vm.askForCamera();
    next();
  },
  methods: {
    init() {
      const vm = this;
      vm.enterRoom();
      window.addEventListener("beforeunload", vm.leavingRoom);
      vm.enviaLogRTC(
        "Chat start\n Cliente => isChrome: " +
          vm.isChrome +
          " vm.accessCam: " +
          vm.accessCam
      );
      vm.askForCredentials(vm.$route.params.medRecId);
    },
    verificaPWA() {
      // Verficia se esta rodando no browser ou pwa
      if (window.matchMedia("(display-mode: standalone)").matches) {
        console.warn("This is running as standalone.");
      }
      if (window.matchMedia("(display-mode: fullscreen)").matches) {
        console.warn("This is running as fullscreen.");
      }
      if (window.matchMedia("(display-mode: minimal-ui)").matches) {
        console.warn("This is running as minimal-ui.");
      }
      if (window.matchMedia("(display-mode: browser)").matches) {
        console.warn("This is running as browser.");
      }
    },
    enterRoom() {
      var vm = this;
      vm.axios
        .put(
          vm.$store.state.filooServer +
            "appointments/room/patient_inroom/" +
            vm.$route.params.medRecId +
            "/1",
          {},
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function () {
          vm.$session.get("doctorPhoto") != null ?
            vm.participants[0].profilePicture = vm.$session.get("doctorPhoto") :
            vm.$session.get("clinicPhoto") != null ?
              vm.participants[0].profilePicture = vm.$session.get("clinicPhoto") :
              vm.participants[0].profilePicture = null;
          vm.specialty = vm.$session.get("specialty");
          vm.crmNumber = vm.$route.query.selected.doctor.crmNumber;
          vm.participants[0].name =
            vm.$route.query.selected.doctor.name !== "[object Object]" &&
            vm.testNullUndefinedEmpty(vm.$route.query.selected.doctor.name) ==
              false
              ? "Dr(a) " + vm.$route.query.selected.doctor.name
              : "Profissional da saúde";
        });
    },
    exitRoom() {
      const vm = this;
      vm.axios
        .put(
          vm.$store.state.filooServer +
            "appointments/room/patient_inroom/" +
            vm.$route.params.medRecId +
            "/0",
          {},
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function () {
          // vm.$router.push('/scheduled');
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    verificaChrome() {
      var vm = this;
      if (vm.isChrome) {
        navigator.permissions
          .query({
            name: "microphone",
          })
          .then((permissionObj) => {
            // "prompt" means requesting
            // "granted" means has access
            // "denied" means does not have access
            if (permissionObj.state === "granted") {
              vm.accessMic = true;
            } else {
              vm.accessMic = false;
            }
          })
          .catch((error) => {
            console.error(error);
          });
        navigator.permissions
          .query({
            name: "camera",
          })
          .then((permissionObj) => {
            if (permissionObj.state === "granted") {
              vm.accessCam = true;
            } else {
              vm.accessCam = false;
            }
          })
          .catch((error) => {
            console.error(error);
            vm.accessCam = false;
          });
      }
    },
    verficaWkWebView() {
      var vm = this;
      if (navigator.platform.substr(0, 2) === "iP") {
        //iOS (iPhone, iPod or iPad)
        var lte9 = /constructor/i.test(window.HTMLElement);
        var nav = window.navigator,
          ua = nav.userAgent,
          idb = !!window.indexedDB;
        if (
          ua.indexOf("Safari") !== -1 &&
          ua.indexOf("Version") !== -1 &&
          !nav.standalone
        ) {
          //Safari (WKWebView/Nitro since 6+)
        } else if ((!idb && lte9) || !window.statusbar.visible) {
          //UIWebView
        } else if (
          (window.webkit && window.webkit.messageHandlers) ||
          !lte9 ||
          idb
        ) {
          //WKWebView
          vm.isWkWebView = true;
        }
      }
    },
    isTWA() {
      return window.document.referrer === "com.filoo.cliente" ? true : false;
    },
    askForCamera() {
      var vm = this;
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: true,
        })
        .then(function (response) {
          if (response.active === true) {
            vm.accessMic = true;
            vm.accessCam = true;
          } else {
            vm.accessMic = false;
            vm.accessCam = false;
          }
          response.getTracks()[0].stop();
          response.getTracks()[1].stop();
          response.getTracks()[2].stop();
        })
        .catch(function (error) {
          //'DOMException: Requested device not found' caso nao tenha
          //'DOMException: Permission denied' caso bloqueado
          if (
            error.name === "NotAllowedError" ||
            error.name === "PermissionDeniedError"
          ) {
            if (vm.supportsTouch) {
              vm.$bvModal.show("modal-cam-chrome-mobile");
            } else {
              vm.popupCamChrome = true;
              vm.$bvModal.show("modal-cam-chrome");
            }
          }
          if (
            error.name === "NotFoundError" ||
            error.name === "DevicesNotFoundError"
          ) {
            this.$bvModal.show("modal-camera-not-found")
          }
          vm.enviaLogRTC(
            "Cliente => askForCamera error: " +
              error.name +
              " - " +
              error.message
          );
        });
    },
    askForCredentials(medRecordId) {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "appointments/room/" + medRecordId, {
          headers: {
            Authorization: vm.$session.get("bearer"),
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            var responseRoom = response.data;
            if (response.data === "ROOMnull") {
              vm.roomUrl = undefined;
            } else {
              vm.roomUrl = "https://webrtc.filoo.com.br/#" + responseRoom[0];
              vm.cardRtc = true;
              vm.askForCamera();
              if (vm.isChrome) {
                if (vm.getChromeVersion() < 60) {
                  vm.$swal({
                    text:
                      "A sua versão do navegador é um pouco antiga e pode encontrar problemas.",
                    icon: "warning",
                  });
                }
              }
            }
          }
          vm.sendRtcToken(medRecordId)
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    sendRtcToken(medRecId) {
      var vm = this;
      setTimeout(function () { // precisa dar tempo para a pagina webrtc iniciar
        vm.axios
          .get(vm.$store.state.filooServer + "appointments/getSessionToken/" + medRecId, {
            headers: {
              Authorization: vm.$session.get("bearer"),
            },
          })
          .then(function (response) {
            const x = window.document.getElementById("webtrcframe");
            const y = (x.contentWindow || x.contentDocument);
            const messageObject = ["rtcToken", response.data];
            y.postMessage(messageObject, "*");
          })
      }, 4000);
    },
    muteVideo() {
      const x = window.document.getElementById("webtrcframe");
      const y = x.contentWindow || x.contentDocument;
      y.postMessage("muteVideoMsg", "*");
      this.videoEnabled = !this.videoEnabled;
    },
    muteAudio() {
      const x = window.document.getElementById("webtrcframe");
      const y = x.contentWindow || x.contentDocument;
      y.postMessage("muteAudioMsg", "*");
      this.audioEnabled = !this.audioEnabled;
    },
    leaveRoom() {
      const x = window.document.getElementById("webtrcframe");
      const y = x.contentWindow || x.contentDocument;
      y.postMessage("leaveRoomMsg", "*");
    },
    /////// Chat room ///////
    sendMessageVidu(mensagem) {
      const x = window.document.getElementById("webtrcframe");
      const y = x.contentWindow || x.contentDocument;
      const mensagemObj = {
        author: this.$session.get("name"),
        type: "text",
        data: {
          author: this.$session.get("name"),
          text: mensagem.content,
          content: mensagem.content,
          myself: true,
          participantId: 3,
          timestamp: mensagem.timestamp,
          uploaded: mensagem.uploaded,
          viewed: mensagem.viewed,
        },
      };

      const messageObject = ["mensagemChatFromPatient", mensagemObj];
      y.postMessage(messageObject, "*");
    },
    getMessageVidu(eventListenerObj) {
      var msgTxt = eventListenerObj;
      var now = new Date();
      var msg = {
        author: "user1",
        type: "text",
        name: "user1",
        content: msgTxt,
        myself: false,
        participantId: null,
        timestamp: {
          year: now.getFullYear(),
          month: now.getMonth(),
          day: now.getDate(),
          hour: now.getHours(),
          minute: now.getMinutes(),
          second: now.getSeconds(),
          millisecond: now.getMilliseconds(),
        },
        data: {
          text: msgTxt,
        },
      };
      this.messages = [...this.messages, msg];
    },
    /////// Chat room ///////
    ////////////////// Antes de refatorar //////////////////
    checkLivingRoom() {
      this.$bvModal.show("check-living-room")
    },
    leavingRoom() {
      var vm = this;
      vm.askForCamera();
      vm.roomUrl = "https://webrtc.filoo.com.br";
      vm.cardRtc = false;
      vm.leaveRoom();
      vm.$session.remove("doctorPhoto")
      vm.$router.push({ name: "scheduled" });
    },
    enviaLogRTC(mensagem) {
      console.warn("Log message: " + mensagem);
      /*      var vm = this;
            vm.axios
              .post(
                vm.$store.state.filooServer +
                "appointments/room/logRoom/" + vm.$route.params.id, {
                  'msg': mensagem
                }, {
                  headers: {
                    Authorization: vm.$session.get("bearer")
                  }
                }
              )
              .then(function (response) {
                console.log('enviaLogRTC');
                console.log(response);
              })
              .catch(function (error) {
                console.log('enviaLogRTC error');
                console.log(error);
              })*/
    },
    ////////////////// Antes de refatorar //////////////////
    loadMoreMessages(resolve) {
      setTimeout(() => {
        resolve(this.toLoad); //We end the loading state and add the messages
        //Make sure the loaded messages are also added to our local messages copy or they will be lost
        this.messages.unshift(...this.toLoad);
        this.toLoad = [];
      }, 1000);
    },
    onMessageSubmit: function (message) {
      this.messages.push(message);
      setTimeout(() => {
        message.uploaded = true;
        this.sendMessageVidu(message);
      }, 2000);
    },
    onClose() {
      this.visible = false;
    },
    onImageSelected(message) {
      const src =
        "https://149364066.v2.pressablecdn.com/wp-content/uploads/2017/03/vue.jpg";
      this.messages.push(message);
      /**
       * This timeout simulates a requisition that uploads the image file to the server.
       * It's up to you implement the request and deal with the response in order to
       * update the message status and the message URL
       */
      setTimeout(
        (res) => {
          message.uploaded = true;
          message.src = res.src;
        },
        3000,
        { src }
      );
    },
    onImageClicked(message) {
      /**
       * This is the callback function that is going to be executed when some image is clicked.
       * You can add your code here to do whatever you need with the image clicked. A common situation is to display the image clicked in full screen.
       */
      console.warn("Image clicked", message.src);
    },
  },
};
</script>

<!--<style lang="scss" src="./consultingRoom.scss"></style>-->
